import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import trim from 'lodash/trim';
import React from 'react';
import { useIntl } from 'react-intl';

import { GuideMetaData, HeroType } from '~/types/UIModules';
import SocialSharing from '~/ui-modules/Hero/SocialSharing/SocialSharing';
import { parseToHtml } from '~/utils/html';

type Props = {
  title: string;
  subtitle: string;
  heroType: HeroType;
  cta?: CTAData;
  secondaryCta?: CTAData;
  guideMetaData?: GuideMetaData;
  label?: string;
  noImage?: boolean;
};

const hasRelevantMetaField = (guideMetaData: GuideMetaData) => {
  return trim(guideMetaData?.date) || trim(guideMetaData?.reading_time);
};

const HeroContent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  heroType,
  cta,
  secondaryCta,
  guideMetaData,
  label,
  noImage,
}) => {
  const intl = useIntl();

  return (
    <div
      data-nativeappid="header-content"
      className={cn(
        'whitespace-prewrap flex w-auto flex-col py-8 sm:py-16 md:flex-row lg:py-32',
        { 'pb-0': noImage }
      )}
    >
      <div className="relative">
        {label ? (
          <div className="absolute -top-10">
            <span className="body-12-medium border-pearl block rounded-sm border border-solid p-2">
              {label}
            </span>
          </div>
        ) : null}
        <div className="mt-4 max-w-[832px] break-words">
          <h1
            className={`mobile-t2-semibold md:web-t2-semibold ${heroType === HeroType.primary ? 'text-white' : 'text-black'}`}
          >
            {parseToHtml(title)}
          </h1>
        </div>

        {subtitle && (
          <div className="mt-8 md:max-w-[720px] lg:max-w-[800px]">
            <p className="body-16-light">{parseToHtml(subtitle)}</p>
          </div>
        )}

        {(cta || secondaryCta) && (
          <div className="mt-4 flex flex-wrap items-start gap-4 sm:mt-8">
            {cta && <CTA data={cta.metadata} />}
            {secondaryCta && <CTA data={secondaryCta.metadata} />}
          </div>
        )}
      </div>
      {hasRelevantMetaField(guideMetaData) && (
        <div className="flex flex-wrap items-start py-7 md:ml-auto md:p-0">
          {guideMetaData.reading_time && (
            <div className="basis-1/2 py-3 md:basis-full md:pb-6 md:pl-6">
              <h6 className="global-t6-semibold">
                {intl.formatMessage({
                  id: 'general.guideReadTime',
                })}
              </h6>
              <p className="body-16-light mt-3">{guideMetaData.reading_time}</p>
            </div>
          )}
          {guideMetaData.date && (
            <div className="basis-1/2 py-3 md:basis-full md:pb-6 md:pl-6">
              <h6 className="global-t6-semibold">
                {intl.formatMessage({
                  id: 'general.guideDate',
                })}
              </h6>
              <p className="body-16-light mt-3">{guideMetaData.date}</p>
            </div>
          )}
          <div className="flex-grow basis-1/2 py-3 md:basis-full md:pb-6 md:pl-6">
            <h6 className="global-t6-semibold mb-2">
              {intl.formatMessage({
                id: 'general.guideShare',
              })}
            </h6>
            <SocialSharing />
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroContent;
