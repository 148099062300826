import { forwardRef, ForwardRefRenderFunction } from 'react';

type Props = {
  url: string;
  alt: string;
  index: number;
  totalImages: number;
};

const GalleryItem: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { url, alt, index, totalImages },
  forwardedRef
) => {
  const firstInBlock = index % 3 === 0;
  const lastInBlock = index % 3 === 2;
  const blockNumber = Math.floor(index / 3);
  const colStart = blockNumber * 2 + (firstInBlock ? 0 : 1);

  return (
    <div
      className="w-full bg-cover bg-center"
      role="img"
      aria-label={alt}
      style={{
        backgroundImage: `url(${url})`,
        gridColumn: `${colStart + 1}/${colStart + 2}`,
        gridRow: firstInBlock
          ? '1/3'
          : lastInBlock
            ? '2/3'
            : totalImages === index + 1
              ? '1/3'
              : '1/2',
      }}
      {...{ ref: forwardedRef }}
    />
  );
};

export default forwardRef(GalleryItem);
