import { FeaturedVehicleCosmicMetadata } from '@finn/ui-cosmic';

import FeaturedVehicleImage from './FeaturedVehicleImage';
import FeaturedVehicleText from './FeaturedVehicleText';

type Props = {
  data: FeaturedVehicleCosmicMetadata;
};

const FeaturedVehicle = ({ data }: Props) => {
  const textData = {
    title: data.title,
    description: data.description,
    button_href: data.button_href,
    button_text: data.button_text,
    cta: data.cta,
  };
  const imageData = {
    title: data.title,
    image: data.image,
  };

  return (
    <>
      <div className="hidden md:block">
        <div className="container">
          <div className="grid w-full gap-12 sm:grid-cols-[5fr,7fr] sm:gap-6">
            <FeaturedVehicleText data={textData} />
            <FeaturedVehicleImage data={imageData} />
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="container">
          <FeaturedVehicleText data={textData} />
        </div>
        <FeaturedVehicleImage data={imageData} />
      </div>
    </>
  );
};
export default FeaturedVehicle;
